import "bootstrap";
import "@splidejs/splide/dist/js/splide.min.js";

window.Swal = require('sweetalert2');

// Show search form
// let searchButton = document.getElementById("search-button");
// let searchContainer = document.getElementById("search-container");
// searchButton.addEventListener("click", () => {
//     searchContainer.classList.toggle('d-none')
// });
